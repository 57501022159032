import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "rate-limits-for-api"
    }}>{`Rate limits for API`}</h1>
    <p>{`In order to ensure our API remains available for all customers and to prevent abuse, denial-of-service and other unintended issues, our API is subject to a rate limit:`}</p>
    <ul>
      <li parentName="ul">{`Available requests per second: 10 per client id`}</li>
      <li parentName="ul">{`Available requests per second: 10 per portal`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`Available requests per minute: 500 per client id`}</li>
      <li parentName="ul">{`Available requests per minute: 500 per per portal`}</li>
    </ul>
    <p>{`Exceeding the rate limit will result in a `}<inlineCode parentName="p">{`429`}</inlineCode>{` response from our API and you should not attempt to retry until after the rate limit counter resets. A request will always contain one client id and portal and will always increase the counter for both.`}</p>
    <h2 {...{
      "id": "viewing-the-status-of-the-rate-limit"
    }}>{`Viewing the status of the rate limit`}</h2>
    <p>{`You can inspect the following HTTP headers of each response to determine the current status of your rate limit.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP header`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x-ratelimit-reset`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Remaining seconds before the counter resets in the current window (per second or per minute)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x-ratelimit-remaining`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of remaining allowed requests in the current window (per second or per minute)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x-ratelimit-limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The current limit with quotas for both rate limit per second and per minute`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "examples"
    }}>{`Examples`}</h3>
    <p>{`1) The client id used to send the request has not been rate limited. The request felt into the "10 requests per second per client id" bucket:`}</p>
    <pre><code parentName="pre" {...{}}>{`$ curl -v 'https://openapi.planday.com/hr/v1/Departments' -H "Authorization: Bearer $token"
...
< HTTP/2 200
...
< x-ratelimit-limit: 10, 10;w=1, 500;w=60, 10;w=1, 500;w=60
< x-ratelimit-remaining: 9
< x-ratelimit-reset: 1
...
`}</code></pre>
    <p>{`How to decode the `}<inlineCode parentName="p">{`x-ratelimit-limit: 10, 10;w=1, 500;w=60, 10;w=1, 500;w=60`}</inlineCode>{` header ?`}</p>
    <p>{`Since the rate limiting policy specifies more then one time window, the values above represent the window that is closest to reaching its limit.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`10,`}</inlineCode>{` indicates the request-quota associated to the client in the current time-window `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`10;w=1, 500;w=60, 10;w=1, 500;w=60`}</inlineCode>{` is the description of the quota policy: `}
        <ul parentName="li">
          <li parentName="ul">{`The given client id is allowed 10 requests per second (`}<inlineCode parentName="li">{`10;w=1`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`The given client id is allowed 500 requests per minute (`}<inlineCode parentName="li">{`500;w=60`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`The given portal is allowed 10 requests per second (`}<inlineCode parentName="li">{`10;w=1`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`The given portal is allowed 500 requests per minute (`}<inlineCode parentName="li">{`500;w=60`}</inlineCode>{`)`}</li>
        </ul>
      </li>
    </ul>
    <p>{`The client id is then allowed 9 more requests in the current time window (`}<inlineCode parentName="p">{`x-ratelimit-remaining`}</inlineCode>{`) and it will be reset after 1 second (`}<inlineCode parentName="p">{`x-ratelimit-reset`}</inlineCode>{`).`}</p>
    <hr></hr>
    <p>{`2) The client id used to send the request has been rate limited. The request felt into the "500 requests per minute per client id" bucket:`}</p>
    <pre><code parentName="pre" {...{}}>{`$ curl -v 'https://openapi.planday.com/hr/v1/Departments' -H "Authorization: Bearer $token"
...
< HTTP/2 429
...
< x-ratelimit-limit: 500, 10;w=1, 500;w=60, 10;w=1, 500;w=60
< x-ratelimit-remaining: 0
< x-ratelimit-reset: 33
...
`}</code></pre>
    <p>{`The HTTP response code is `}<inlineCode parentName="p">{`429`}</inlineCode>{` which indicate the request has been rate limited and the counter will be reset in 33 seconds.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      